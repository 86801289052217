var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drawer-container" }, [
    _c("div", [
      _c("h3", { staticClass: "drawer-title" }, [_vm._v("布局配置")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v("开启 Tags-Views")]),
          _vm._v(" "),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.tagsView,
              callback: function ($$v) {
                _vm.tagsView = $$v
              },
              expression: "tagsView",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v("固定 Header")]),
          _vm._v(" "),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.fixedHeader,
              callback: function ($$v) {
                _vm.fixedHeader = $$v
              },
              expression: "fixedHeader",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v("显示 Logo")]),
          _vm._v(" "),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.sidebarLogo,
              callback: function ($$v) {
                _vm.sidebarLogo = $$v
              },
              expression: "sidebarLogo",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }