/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(http?:|https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * 验证密码复杂度
 * @param {string} password
 * @returns {Boolean}
 */
export function validatePassword(password) {
  // 至少8个字符且不能为空
  if (!password || password.length < 8) {
    return false;
  }

  // 使用与后端相同的正则表达式验证密码
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&]).*$/;
  return regex.test(password);
}

/**
 * 检查密码是否过期
 * @param {Date} lastPasswordChangeDate
 * @returns {Boolean}
 */
export function isPasswordExpired(lastPasswordChangeDate) {
  const now = new Date();
  const daysSinceChange = (now - lastPasswordChangeDate) / (1000 * 60 * 60 * 24);
  return daysSinceChange > 90;
}

/**
 * 检查密码是否在历史记录中
 * @param {string} newPassword
 * @param {Array} passwordHistory
 * @returns {Boolean}
 */
export function isPasswordInHistory(newPassword, passwordHistory) {
  return passwordHistory.includes(newPassword);
}

/**
 * 更新登录尝试次数
 * @param {Object} account
 * @param {Boolean} success
 */
export function updateLoginAttempts(account) {
  if (!account.failedAttempts) {
    account.failedAttempts = 0;
  }
  
  account.failedAttempts += 1;
  account.lastFailedTime = new Date();
  
  if (account.failedAttempts >= 5) {
    account.isLocked = true;
  }
}

/**
 * 检查账户是否被锁定
 * @param {Object} account
 * @returns {Boolean}
 */
export function isAccountLocked(account) {
  if (!account.isLocked) {
    return false;
  }

  // 检查是否已经过了30分钟
  const now = new Date();
  const minutesSinceLastAttempt = (now - account.lastFailedTime) / (1000 * 60);
  
  if (minutesSinceLastAttempt >= 30) {
    account.isLocked = false;
    account.failedAttempts = 0;
    return false;
  }
  
  return true;
}

/**
 * 检查是否需要修改密码
 * @param {Object} user
 * @returns {Boolean}
 */
export function requirePasswordChange(user) {
  return user.isFirstLogin && !user.hasChangedDefaultPassword;
}
