var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
    _vm.visible && _vm.programData
      ? _c(
          "div",
          {
            ref: "preview",
            staticClass: "preview",
            on: {
              mousewheel: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("div", { staticClass: "main-area" }, [
              _c("div", { staticClass: "btns-wrapper" }, [
                _c("div", { staticClass: "page-info" }, [
                  _c("span", { staticClass: "curr" }, [
                    _vm._v(_vm._s(_vm.currentPageIdx + 1)),
                  ]),
                  _vm._v("/" + _vm._s(_vm.programData.pages.length)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: ["pre", { active: _vm.preActive }],
                    on: { click: _vm.handlePre },
                  },
                  [_vm._v("上一页")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: ["next", { active: _vm.nextActive }],
                    on: { click: _vm.handleNext },
                  },
                  [_vm._v("下一页")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "exit",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("update:visible", false)
                      },
                    },
                  },
                  [_vm._v("退出")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container", style: _vm.containerStyle },
                _vm._l(
                  _vm.programData.pages[
                    _vm.currentPageIdx
                  ].componentList.filter(function (it) {
                    return !it.hide
                  }),
                  function (component, index) {
                    return _c(
                      "tumeng-draggable",
                      {
                        key: component.componentId,
                        attrs: { idx: index, conf: component, preview: true },
                      },
                      [
                        _c(component.type, {
                          tag: "component",
                          attrs: { conf: component, preview: true },
                        }),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }