import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getAccessToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect', '/bind', '/register']

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  const token = getAccessToken()
  console.log('Navigation guard - Token:', token);
  console.log('Navigation guard - Target path:', to.path);
  console.log('Navigation guard - Cookie check:', document.cookie);

  if (token) {
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (!store.getters.roles || store.getters.roles.length === 0) {
        console.log('Navigation guard - No roles, fetching user info...');
        try {
          // 判断当前用户是否已拉取完user_info信息
          await store.dispatch('GetInfo')
          console.log('Navigation guard - User info fetched, generating routes...');
          // 生成路由
          const accessRoutes = await store.dispatch('GenerateRoutes')
          console.log('Navigation guard - Routes generated:', accessRoutes);
          // 动态添加可访问路由表
          router.addRoutes(accessRoutes)
          console.log('Navigation guard - Proceeding to:', to.path);
          next({ ...to, replace: true })
        } catch (err) {
          console.error('Navigation guard - Error:', err);
          await store.dispatch('FedLogOut')
          Message.error(err.toString())
          next({ path: '/login' })
        }
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
