import axios from 'axios'
import NProgress from 'nprogress'
import {MessageBox, Message} from 'element-ui'
import store from '@/store'
import errorCode from '@/utils/errorCode'
import {getAccessToken} from '@/utils/auth'

// 添加跳转状态标记
let isRedirecting = false;

// 统一的跳转处理函数
const handleTokenExpired = () => {
  if (isRedirecting) {
    return Promise.reject(new Error('正在跳转中...'));
  }
  
  isRedirecting = true;
  Message({
    message: '登录已过期，请重新登录',
    type: 'warning',
    duration: 3000
  });
  
  // 直接跳转到主页
  window.location.replace('/index');
  return Promise.reject(new Error('登录已过期，请重新登录'));
};

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.validateStatus = function (status) {
  return true; // 允许所有状态码，让拦截器处理
}

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})

NProgress.configure({
  showSpinner: false
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    NProgress.start()
    console.log('Request interceptor - URL:', config.url);

    // 对于修改操作添加临时安全Cookie
    if (['post', 'put', 'delete'].includes(config.method.toLowerCase())) {
      const securityToken = Math.random().toString(36).substring(7);
      document.cookie = `security_token=${securityToken};max-age=1;path=/`;
      config.headers['X-Security-Token'] = securityToken;
    }

    const isToken = (config.headers || {}).isToken === false;
    const token = store.getters.access_token;
    
    if (token && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    NProgress.done();

    // 处理 JSON 劫持防护前缀
    if (typeof response.data === 'string' && response.data.startsWith(")]}'")) {
      try {
        response.data = JSON.parse(response.data.substring(4));
      } catch (e) {
        console.error('Failed to parse response data:', e);
      }
    }

    const code = response.data.code || response.status;
    const msg = response.data.msg || errorCode[code] || errorCode['default'];

    // 二维码失效
    if (code === 428) {
      Message({
        message: msg,
        type: 'error'
      });
      return Promise.reject(new Error(msg));
    }

    // 处理400错误
    if (code === 400) {
      if (response.data.error === 'invalid_grant') {
        const errorMsg = response.data.error_description || '密码错误或已过期';
        Message({
          message: errorMsg,
          type: 'error'
        });
        return Promise.reject(new Error(errorMsg));
      }
    }

    // 处理token过期或无效
    if (code === 401 || (code === 500 && response.data.msg && response.data.msg.includes('Invalid access token'))) {
      return handleTokenExpired();
    }

    if (code === 200) {
      // 检查是否有密码警告
      const data = response.data;
      if (data && data.password_warning) {
        Message({
          message: data.password_warning,
          type: 'warning',
          duration: 10000
        });
      }
      return response.data;
    }

    Message({
      message: msg,
      type: 'error'
    });
    return Promise.reject(new Error(msg));
  },
  error => {
    NProgress.done();
    console.error('Response error:', error);
    let { message } = error;
    
    if (message === 'Network Error') {
      message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    } else if (message.includes('Request failed with status code')) {
      const statusCode = message.substr(message.length - 3);
      // 处理token过期或无效的情况
      if (statusCode === '401' || 
          (statusCode === '500' && error.response && 
           error.response.data && 
           error.response.data.msg && 
           error.response.data.msg.includes('Invalid access token'))) {
        return handleTokenExpired();
      }
      message = '系统接口' + statusCode + '异常';
    }
    
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export default service
