<template>
  <transition name="el-fade-in-linear">
    <div v-if="visible && programData" ref="preview" class="preview" @mousewheel.prevent>
      <div class="main-area">
        <div class="btns-wrapper">
          <div class="page-info">
            <span class="curr">{{ currentPageIdx + 1 }}</span>/{{ programData.pages.length }}</div>
          <div :class="['pre', {'active': preActive}]" @click="handlePre">上一页</div>
          <div :class="['next', {'active': nextActive}]" @click="handleNext">下一页</div>
          <div class="exit" @click="$emit('update:visible', false)">退出</div>
        </div>
        <div class="container" :style="containerStyle">
          <tumeng-draggable
            v-for="(component, index) in programData.pages[currentPageIdx].componentList.filter(it => !it.hide)"
            :key="component.componentId"
            :idx="index"
            :conf="component"
            :preview="true"
          >
            <component :is="component.type" :conf="component" :preview="true" />
          </tumeng-draggable>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'Preview',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      programData: {
        type: Object,
        default: function() {
          return null
        }
      }
    },
    data() {
      return {
        currentPageIdx: 0,
        containerWidth: null,
        containerHeight: null
      }
    },
    computed: {
      currentPageComponentList() {
        return this.programData.pages[this.currentPageIdx].componentList
      },
      containerStyle() {
        if (!this.containerWidth || !this.containerHeight) {
          return {}
        }
        const scaleX = this.containerWidth / this.programData.width
        const scaleY = this.containerHeight / this.programData.height
        let top
        let left
        if (scaleX <= 1.0) {
          left = '-' + ((this.programData.width / 2) * (1 - scaleX)) + 'px'
        } else {
          left = ((this.programData.width / 2) * (scaleX - 1)) + 'px'
        }
        if (scaleY <= 1.0) {
          top = '-' + ((this.programData.height / 2) * (1 - scaleY)) + 'px'
        } else {
          top = ((this.programData.height / 2) * (scaleY - 1)) + 'px'
        }
        const showScaleX = this.containerWidth > this.programData.width ? 1 : this.containerWidth / this.programData.width
        const showScaleY = this.containerHeight > this.programData.height ? 1 : this.containerHeight / this.programData.height
        const showScale = showScaleX < showScaleY ? showScaleX : showScaleY
        const style = {
          position: 'absolute',
          width: this.programData.width + 'px',
          height: this.programData.height + 'px',
          background: '#fff',
          transform: `scaleX(${showScale}) scaleY(${showScale})`,
          left,
          top
        }
        if (this.programData.pages[this.currentPageIdx].bgColor) {
          style.backgroundColor = this.programData.pages[this.currentPageIdx].bgColor
        }
        if (this.programData.pages[this.currentPageIdx].bgImg) {
          style.backgroundImage = `url(${this.programData.pages[this.currentPageIdx].bgImg})`
          style.backgroundSize = '100% 100%'
        }
        return style
      },
      preActive() {
        return this.currentPageIdx > 0
      },
      nextActive() {
        return this.currentPageIdx < this.programData.pages.length - 1
      }
    },
    watch: {
      visible(val) {
        if (val) {
          this.currentPageIdx = 0
          this.getWH()
        }
      }
    },
    created() {
      this.getWH()
    },
    methods: {
      getWH() {
        if (!this.$refs.preview) {
          return setTimeout(this.getWH, 1)
        }
        const rect = this.$refs.preview.getBoundingClientRect()
        this.containerWidth = rect.width * 0.8
        this.containerHeight = rect.height * 0.8
      },
      handlePre() {
        if (this.preActive) {
          this.currentPageIdx -= 1
        }
      },
      handleNext() {
        if (this.nextActive) {
          this.currentPageIdx += 1
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @mixin clearfix() {
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
  .preview {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1100;
    background-color: rgba(0,0,0,.5);
    .main-area {
      position: absolute;
      left: 10%;
      right: 10%;
      top: calc(10% - 22px);
      bottom: calc(10% - 22px);
      .btns-wrapper {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        @include clearfix;
        .pre, .next, .exit {
          padding: 8px 14px;
          border-radius: 4px;
          cursor: pointer;
          user-select: none;
          &:not(:last-child) {
            margin-right: 8px;
          }
        }
        .pre, .next {
          float: left;
          background-color: #808695;
          &.active {
            &:hover {
              background-color: #e3e7ee;
            }
          }
        }
        .exit {
          float: right;
          color: #fff;
          background-color: #ed4014;
          &:hover {
            background-color: #f16643;
          }
        }
        .page-info {
          float: left;
          font-size: 16px;
          padding: 8px 16px;
          z-index: 300;
          background-color: #fff;
          margin-right: 8px;
          border-radius: 4px;
          .curr {
            color: orangered;
          }
        }
      }
    }
  }
</style>
