import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './assets/styles/element-variables.scss'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/mips.scss' // mips css
import './assets/dashboard/css/style.css';  // 全局引入看板样式
import App from './App'
import store from './store'
import router from './router'
import permission from './directive/permission'

// import UILibrary from './assets/js/editor'
// Vue.use(UILibrary.TumengEditor)
// Vue.use(UILibrary.TumengDraggable)
// Vue.use(UILibrary.TumengText)
// Vue.use(UILibrary.TumengMultiImg)
// Vue.use(UILibrary.TumengVideo)
// Vue.use(UILibrary.TumengWebPage)
// Vue.use(UILibrary.TumengWeather)
// Vue.use(UILibrary.TumengPdf)

import './assets/icons' // icon
import './permission' // permission control
import './assets/styles/animate.css'
import { getDicts } from "@/api/system/dictData";
import { getByKey } from "@/api/system/config";
import { parseTime, newPath, resetForm, addDateRange, selectDictLabel, download, handleTree } from "@/utils/mips";
import Pagination from "@/components/Pagination";

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  ak: 'GzfmMzKgbfkjguSxveLyUXoopZZrpmZq'
})

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getByKey = getByKey
Vue.prototype.parseTime = parseTime
Vue.prototype.newPath = newPath
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.$EventBus = new Vue()

Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgWarning = function (msg) {
  this.$message({ showClose: true, message: msg, type: "warning" });
}

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}

// 全局组件挂载
Vue.component('Pagination', Pagination)

Vue.use(permission)

Vue.use(mavonEditor)



/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
