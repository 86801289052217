import Cookies from 'js-cookie'

const AccessTokenKey = 'mips_access_token'
const RefreshTokenKey = 'mips_refresh_token'

export function getAccessToken() {
  return Cookies.get(AccessTokenKey)
}

export function setAccessToken(token) {
  return Cookies.set(AccessTokenKey, token, {
    path: '/',
    sameSite: 'Lax'
  })
}

export function removeAccessToken() {
  return Cookies.remove(AccessTokenKey, {
    path: '/'
  })
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token) {
  return Cookies.set(RefreshTokenKey, token, {
    path: '/',
    sameSite: 'Lax'
  })
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey, {
    path: '/'
  })
}
